import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useChat } from "./ChatProvider";
const ChannelContext = React.createContext();
const UNIQUE_HANDLER_ID = 2;
const useChannelList = (sb, chatInitializing) => {
  const [loadingChannels, setLoadingChannels] = useState(true);
  const [channels, setChannels] = useState();
  const reload = () => {
    if (sb && !chatInitializing) {
      const listQuery = sb.GroupChannel.createMyGroupChannelListQuery();
      listQuery.includeEmpty = true;
      listQuery.memberStateFilter = "joined_only"; // 'all', 'joined_only', 'invited_only', 'invited_by_friend', and 'invited_by_non_friend'
      listQuery.order = "latest_last_message"; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
      listQuery.limit = 15; // The value of pagination limit could be set up to 100.
      if (listQuery.hasNext) {
        listQuery.next(function (groupChannels, error) {
          setLoadingChannels(false);
          setChannels(groupChannels);
          if (error) console.error(error);
        });
      }
    }
  };
  useEffect(() => {
    reload();
  }, [sb, chatInitializing]);
  return { channels, reload, loadingChannels };
};

export const useChannels = () => {
  const { channels, reload, loadingChannels } = useContext(ChannelContext);
  return { channels, reload, loadingChannels };
};

export default function ChannelProvider({ children }) {
  const { sendBirdSdk, chatInitializing } = useChat();
  const { channels, reload, loadingChannels } = useChannelList(
    sendBirdSdk,
    chatInitializing
  );
  useEffect(() => {
    if (sendBirdSdk && !chatInitializing) {
      var channelHandler = new sendBirdSdk.ChannelHandler();
      channelHandler.onChannelChanged = () => {
        reload();
      };
      sendBirdSdk.addChannelHandler(UNIQUE_HANDLER_ID, channelHandler);
    }
  }, [sendBirdSdk, chatInitializing]);
  return (
    <ChannelContext.Provider value={{ channels, reload, loadingChannels }}>
      {children}
    </ChannelContext.Provider>
  );
}
