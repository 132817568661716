import React, { useState } from "react";
import { useGetCurrentChannel } from "../services/channelsService";
import { useChat } from "./ChatProvider";
import { useCurrentChannel } from "./CurrentChannelProvider";
import useMessageList from "./useMessageList";
import { uuid } from "uuidv4";
import { checkFileFormat } from "../utilis/miscFunctions";

const ChannelMessagesContext = React.createContext();

export const useChannelMessages = () => {
  const {
    sendMessage,
    groupedMessages,
    sendingMessage,
    loadNewMessages,
    channel,
    actions,
    loading,
    sendFileMessage,
    messageQueue,
    addMessage
  } = React.useContext(ChannelMessagesContext);
  return {
    sendMessage,
    groupedMessages,
    sendingMessage,
    loadNewMessages,
    channel,
    actions,
    loading,
    sendFileMessage,
    messageQueue,
    addMessage
  };
};

export const FILE_UPDLOAD_STATUS = {
  PENDING: "PENDING",
  ERROR: "ERROR",
};

const useFileMessageQueue = () => {
  const [messageQueue, setMessageQueue] = useState({});
  const addNewFileMessage = (uuid, message) => {
    setMessageQueue((oldMessageQueue) => ({
      ...oldMessageQueue,
      [uuid]: { message, progress: 0, status: FILE_UPDLOAD_STATUS.PENDING },
    }));
  };
  const updateProgress = (uuid, newProgress) => {
    setMessageQueue((oldMessageQueue) => ({
      ...oldMessageQueue,
      [uuid]: { ...oldMessageQueue[uuid], progress: newProgress },
    }));
  };
  const removeFileFromQueue = (uuid) => {
    setMessageQueue((oldMessageQueue) => {
      const oldMessageQueueCopy = { ...oldMessageQueue };
      delete oldMessageQueueCopy[uuid];
      return oldMessageQueueCopy;
    });
  };
  const updateStatus = (uuid, status) => {
    setMessageQueue((oldMessageQueue) => ({
      ...oldMessageQueue,
      [uuid]: { ...oldMessageQueue[uuid], status },
    }));
  };
  return {
    messageQueue,
    addNewFileMessage,
    updateProgress,
    removeFileFromQueue,
    updateStatus,
  };
};

function InnerChannelMessagesProvider({ children }) {
  const { currentChannelUrl } = useCurrentChannel();
  const channel = useGetCurrentChannel(currentChannelUrl);
  const { addMessage, loadNewMessages, actions, loading, groupedMessages } =
    useMessageList(channel);
  const { sendBirdSdk } = useChat();
  const [sendingMessage, setSendingMessage] = useState(false);
  const {
    messageQueue,
    addNewFileMessage,
    updateProgress,
    removeFileFromQueue,
    updateStatus,
  } = useFileMessageQueue();
  const sendMessage = (values) => {
    const params = new sendBirdSdk.UserMessageParams();
    params.message = values.message;
    setSendingMessage(true);
    channel.sendUserMessage(params, (sentMessage, error) => {
      setSendingMessage(false);
      if (error) {
        return console.error('sentMessage Error', error);
      } else {
        addMessage(sentMessage);
      }
    });
  };

  const sendFileMessage = (file) => {
    const params = new sendBirdSdk.FileMessageParams();
    params.file = file;
    params.fileName = file.name;
    params.fileSize = file.size;
    const fileUUID = uuid();
    addNewFileMessage(fileUUID, params);
    if (!checkFileFormat(file.type)) {
      updateStatus(fileUUID, FILE_UPDLOAD_STATUS.ERROR);
      return;
    }
    channel.sendFileMessage(
      params,
      (event) => {
        console.log(
          parseInt(Math.floor((event.loaded / event.total) * 100)) + "%"
        );
        updateProgress(
          fileUUID,
          parseInt(Math.floor((event.loaded / event.total) * 100))
        );
      },
      (fileMessage, error) => {
        if (error) {
          updateStatus(fileUUID, FILE_UPDLOAD_STATUS.ERROR);
        } else {
          removeFileFromQueue(fileUUID);
          addMessage(fileMessage);
        }
      }
    );
  };

  return (
    <ChannelMessagesContext.Provider
      value={{
        sendMessage,
        groupedMessages,
        sendingMessage,
        loadNewMessages,
        channel,
        addMessage,
        actions,
        loading,
        sendFileMessage,
        messageQueue,
      }}
    >
      {children}
    </ChannelMessagesContext.Provider>
  );
}

export default function ChannelMessagesProvider({ children }) {
  const { currentChannelUrl } = useCurrentChannel();
  return (
    <InnerChannelMessagesProvider key={currentChannelUrl}>
      {children}
    </InnerChannelMessagesProvider>
  );
}
