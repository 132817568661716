import React, { useEffect } from "react";
import { Suspense } from "react";
import { Route, Switch,useHistory } from "react-router";
import styled from "styled-components";
import { useAuthContext } from "../context/AuthProvider";
import ChatRoute from "../routes/ChatRoute";
import FadeLoader from "react-spinners/FadeLoader";

const LazyMainRoute = React.lazy(() => import("../routes/MainRoute"));
const AuthRoute = ({ children }) => {
  const { accessToken } = useAuthContext();
  const history = useHistory();
  useEffect(() => {
    if (accessToken) {
      console.log(accessToken);
      history.replace("/inbox");
    } else {
      history.replace("/");
    }
  }, [accessToken]);
  return accessToken ? children : <></>;
};

const LoadingContainer = styled.div`
  position: absolute;
  background-color: white;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingComponent = () => {
  return (
    <LoadingContainer>
      <FadeLoader color="#6273fc" />
    </LoadingContainer>
  );
};

export default function ChatRouter() {
  return (
    <Suspense fallback={<LoadingComponent />}>
      <Switch>
        <Route path="/" exact component={ChatRoute} />
        <Route path="/inbox">
          <AuthRoute>
            <LazyMainRoute />
          </AuthRoute>
        </Route>
      </Switch>
    </Suspense>
  );
}
