import { format } from "date-fns";
import _ from "lodash";

export const ACTION_TYPES = {
  SHIFT_MESSAGES: "SHIFT_MESSAGES",
  PUSH_MESSAGES_CHANNEL_SENT: "PUSH_MESSAGES_CHANNEL_SENT",
  PUSH_MESSAGES_CHANNEL_RECEIVED: "PUSH_MESSAGES_CHANNEL_RECEIVED",
  SET_CHANNEL_URL: "SET_CHANNEL_URL",
  LOADING_OLD_MESSAGES: "LOADING_OLD_MESSAGES",
  FIRST_MESSAGES: "FIRST_MESSAGES",
};

const buildGroupedMessages = (messages) => {
  const currentDate = new Date();
  const newGroupedMessages = _.groupBy(messages, (message) => {
    const orderedDate = new Date(message.createdAt);
    if (currentDate.getFullYear() !== orderedDate.getFullYear()) {
      return format(message.createdAt, "E, LLL d y");
    }
    return format(message.createdAt, "E, LLL d");
  });
  return newGroupedMessages;
};
const messagesReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOADING_OLD_MESSAGES:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPES.SET_CHANNEL_URL:
      return {
        ...state,
        channelUrl: action.payload,
        actions: [...state.actions, action.type],
      };
    case ACTION_TYPES.FIRST_MESSAGES:
    case ACTION_TYPES.SHIFT_MESSAGES:
      return {
        ...state,
        messages: [...action.payload, ...state.messages],
        groupedMessages: buildGroupedMessages([
          ...action.payload,
          ...state.messages,
        ]),
        actions: [...state.actions, action.type],
        loading: false,
      };
    case ACTION_TYPES.PUSH_MESSAGES_CHANNEL_SENT:
    case ACTION_TYPES.PUSH_MESSAGES_CHANNEL_RECEIVED: {
      console.log("NEW MESSAGE");
      const newChannelMessages = action.payload.filter(
        (message) => message.channelUrl === state.channelUrl
      );
      const messagesUnion = _.unionBy(
        state.messages,
        newChannelMessages,
        "messageId"
      );
      return {
        ...state,
        messages: messagesUnion,
        actions: [...state.actions, action.type],
        groupedMessages: buildGroupedMessages(messagesUnion),
      };
    }
    default:
      return state;
  }
};

export const ACTIONS = {
  setChannelUrl: (channelUrl) => ({
    type: ACTION_TYPES.SET_CHANNEL_URL,
    payload: channelUrl,
  }),
  shiftMessages: (olderMessages) => ({
    type: ACTION_TYPES.SHIFT_MESSAGES,
    payload: olderMessages,
  }),
  pushNewMessagesSent: (newMessages) => ({
    type: ACTION_TYPES.PUSH_MESSAGES_CHANNEL_SENT,
    payload: newMessages,
  }),
  pushNewMessagesReceived: (newMessages) => ({
    type: ACTION_TYPES.PUSH_MESSAGES_CHANNEL_RECEIVED,
    payload: newMessages,
  }),
  loadingOldMessages: () => ({
    type: ACTION_TYPES.LOADING_OLD_MESSAGES,
  }),
  firstMessages: (messages) => ({
    type: ACTION_TYPES.FIRST_MESSAGES,
    payload: messages,
  }),
};
export default messagesReducer;
