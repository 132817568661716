import React from "react";
import styled from "styled-components";
import Header from "./Header"

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  background-color: #f4f7f8;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 80vw;
  height: 100vh;
  background-color: white;
  @media (max-width: 1000px) {
    width: 100vw;
    height: 87.5vh;
  }
  @media (max-width: 1000px) and (min-width: 800px) {
    width: 95vw;
  }
  @media (max-width: 800px) and (min-width: 650px) {
    width: 97.5vw;
  }
`;

export default function AppShell({ children }) {
  return (
    <AppContainer>
      <Header />
      <ContentContainer>
        {children}
      </ContentContainer>
    </AppContainer>
  );
}
