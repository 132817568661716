import { useEffect, useReducer, useState } from "react";
import messagesReducer, { ACTIONS } from "./messagesReducer";
import { useNewMessages } from "./NewMessagesProvider";
const initialState = {
  messages: [],
  loading: false,
  channelUrl: "",
  actions: [],
  groupedMessages: [],
};
//This hooks handles all the logic for reciving and updating messages
const useMessageList = (channel) => {
  const [state, dispatch] = useReducer(messagesReducer, initialState);
  const { messages: newMessages } = useNewMessages();
  const [listQuery, setListQuery] = useState();

  const loadNewMessages = () => {
    if (!state.loading && listQuery.hasMore) {
      dispatch(ACTIONS.loadingOldMessages());
      listQuery.load((newLoadedMessages) => {
        dispatch(ACTIONS.shiftMessages(newLoadedMessages));
      });
    }
  };
  const firsMessageLoad = () => {
    if (listQuery.hasMore) {
      dispatch(ACTIONS.loadingOldMessages());
      listQuery.load((newLoadedMessages) => {
        dispatch(ACTIONS.firstMessages(newLoadedMessages));
      });
    }
  };

  const addMessage = (sentMessage) =>
    dispatch(ACTIONS.pushNewMessagesSent([sentMessage]));

  useEffect(() => {
    if (channel) {
      dispatch(ACTIONS.setChannelUrl(channel.url));
      setListQuery(channel.createPreviousMessageListQuery());
    }
  }, [channel]);

  useEffect(() => {
    if (channel && state.messages) {
      channel.markAsRead();
    }
  }, [channel, state]);

  useEffect(() => {
    if (listQuery) {
      firsMessageLoad();
    }
  }, [listQuery]);

  // useEffect(() => {
  //   console.log("process.env",process.env)
  //   console.log("NEW STATE", state);
  // }, [state]);

  useEffect(() => {
    if (newMessages.length > 0 && channel) {
      dispatch(ACTIONS.pushNewMessagesReceived(newMessages));
    }
  }, [newMessages, channel]);

  return {
    messages: state.messages,
    addMessage,
    loadNewMessages,
    actions: state.actions,
    loading: state.loading,
    groupedMessages: state.groupedMessages,
  };
};

export default useMessageList;
