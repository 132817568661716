import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import usePrevious from "../hooks/usePrevious";
const IsMobileContext = React.createContext();

export const useIsMobile = () => {
  const { isMobile, isKeyboardOpen } = React.useContext(IsMobileContext);
  return { isMobile, isKeyboardOpen };
};

const useIsKeyboardOpen = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [currentHeight, setCurrentHeight] = useState(0);
  const prevHeight = usePrevious(currentHeight);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setCurrentHeight(entries[0].target.clientHeight);
    });
    resizeObserver.observe(document.body);
  }, []);
  useEffect(() => {
    if (currentHeight < prevHeight) {
      setIsKeyboardOpen(true);
    } else if (currentHeight > prevHeight) {
      setIsKeyboardOpen(false);
    }
  }, [currentHeight, prevHeight]);
  return isKeyboardOpen;
};

export default function IsMobileProvider({ children }) {
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const isKeyboardOpen = useIsKeyboardOpen();
  return (
    <IsMobileContext.Provider
      value={{ isMobile, isKeyboardOpen: isMobile && isKeyboardOpen }}
    >
      {children}
    </IsMobileContext.Provider>
  );
}
