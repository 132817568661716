import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
const AuthContext = React.createContext();

export const useAuthContext = () => {
  const { signIn, userId, nickname, hasSubscriptions, hasPurchased, hasViewMatches, trainersBooked, usersBooked, isFytTrainer, accessToken } =
    React.useContext(AuthContext);
  return { signIn, userId, nickname, hasSubscriptions, hasPurchased, hasViewMatches, trainersBooked, usersBooked, isFytTrainer, accessToken };
};

export default function AuthProvider({ children }) {
  const [userId, setUserId] = useState();
  const [nickname, setNickname] = useState();
  const [accessToken, setAccessToken] = useState();
  const [hasSubscriptions, setHasSubscriptions] = useState();
  const [hasPurchased, setHasPurchased] = useState();
  const [hasViewMatches, setHasViewMatches] = useState();
  const [trainersBooked, setTrainersBooked] = useState();
  const [usersBooked, setUsersBooked] = useState();
  const [isFytTrainer, setIsFytTrainer] = useState();
  const history = useHistory();
  const signIn = useCallback(
    ({ userId, nickname, accessToken, hasSubscriptions, hasPurchased, hasViewMatches, trainersBooked, usersBooked, isFytTrainer }) => {
      setUserId(userId);
      setNickname(nickname);
      setAccessToken(accessToken);
      setHasSubscriptions(hasSubscriptions);
      setHasPurchased(hasPurchased);
      setHasViewMatches(hasViewMatches);
      setTrainersBooked(trainersBooked);
      setUsersBooked(usersBooked);
      setIsFytTrainer(isFytTrainer);
    },
    [setUserId, setNickname, accessToken, setHasSubscriptions, setHasPurchased, setHasViewMatches, setTrainersBooked, setUsersBooked, setIsFytTrainer]
  );

  useEffect(() => {
    if (userId && nickname && accessToken) {
      history.push("/inbox");
    }
  }, [userId, nickname, accessToken]);

  return (
    <AuthContext.Provider value={{
      userId, nickname, accessToken, hasSubscriptions, hasPurchased, hasViewMatches, trainersBooked, usersBooked, isFytTrainer, signIn
    }}>
      {children}
    </AuthContext.Provider>
  );
}
