import ENV from "../env";

const getUserData = async (encondedId) => {
  const res = await fetch(`${ENV.apiUrl}/api/v1/decode_user`, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify({
      encoded_id: encondedId,
    }),
  });
  return await res.json();
};

const getUserList = async (encodedId) => {
  const res = await fetch(`${ENV.apiUrl}/api/v1/sb_user_list`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: encodedId,
    },
    method: "GET",
  });
  return await res.json();
};

const createChannelGroup = async (encodedId, recipientId) => {
  const res = await fetch(`${ENV.apiUrl}/api/v1/sb_create_channel`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: encodedId,
    },
    method: "POST",
    body: JSON.stringify({
      recipient_id: recipientId,
    }),
  });
  return await res.json();
};


const createInfectedMessage = async (encodedId, message) => {
  const res = await fetch(`${ENV.apiUrl}/api/v1/sb_create_sanitized_message`, {
    headers: {
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      sender: encodedId,
      content: message
    }),
  });
  return await res.json();
};

const backendService = { getUserData, getUserList, createChannelGroup, createInfectedMessage };
export default backendService;
