import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useAuthContext } from "../context/AuthProvider";
import { useIsMobile } from "../context/IsMobileProvider";
import { fytIcon } from "../assets";
import "../assets/styles/index.css"

const HeaderOuterContainer = styled.div`
  margin-bottom: 10px;
  z-index: 100000;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 16%);
`;

const HeaderContainer = styled.header`
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "AvenirNext";
  letter-spacing: .8px;

  width: 100%;
  max-width: 1270px;
  position: relative;
`;

const FytIcon = styled.img`
  cursor: pointer;
  width: 60px;
  height: 40px;
  box-sizing: content-box;
  padding: 10px 15px;
  margin-left: 30px;

  @media(max-width: 700px) {
    padding: 10px 10px;
    margin-left: 0;
  }
`;

const Links = styled.div`
  display: flex;
  margin-right: 30px;

  @media(max-width: 700px) {
    flex-direction: column;
    height: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    right: 0px;
    width: 200px;
    padding-left: 10px;
    margin-right: 0;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  }
`;

const LinkDiv = styled.div`
  cursor: pointer;
  height: 9vh;
  align-items: center;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  display: flex;
  :hover {
    text-decoration: none;
    background-color: #e8e8e8;
  }

  @media(max-width: 700px) {
    height: 50px;
    border-bottom: 1px solid #efefef;
  }
`;


const Link = styled.a`
  cursor: pointer;
  color: #484848;
  text-decoration: none;
  :hover {
    color: #484848;
    text-decoration: none;
  }
`;

const LinkButton = styled.a`
  cursor: pointer;
  height: 9vh;
  color: #484848;
  align-items: center;
  display: flex;
  text-decoration: none;
  :hover {
    color: #484848;
    text-decoration: none;
  }
`;

const Span = styled.span`
  display: inline-block;
  margin-left: 0.25vw;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
`;

const Menu = styled.ul`
  position: absolute;
  width: 150px;
  display: inline-grid;
  padding: 0;
  top: 100%;
  right: 5vh;
  z-index: 9999999;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);

  @media(max-width: 700px) {
    display: flex;
    flex-direction: column;
    margin: 0;
    position: unset;
    box-shadow: none;
    width: 100%;
  }
`;

const MenuItem = styled.li`
  cursor: pointer;
  display: flex;
  list-style: none;
  height: 50px;
  text-align: left;
  align-items: center;
  padding-right: 8px;
  :hover {
    color: #484848;
    text-decoration: none;
    background-color: #e8e8e8;
  }

  @media(max-width: 700px) {
    border-bottom: 1px solid rgb(239, 239, 239);
  }
`;

const LinkItem = styled.a`
  text-decoration: none;
  color: #484848;
  cursor: pointer;
  padding-left: 1vw;
  text-decoration: none;
  :hover {
    color: #484848;
    text-decoration: none;
    background-color: #e8e8e8;
  }
`;

const HamburgerButton = styled.button`
  width: 2rem;
  height: 2rem;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
  background-color: transparent;
  border: none;
  display: flex;
  position: fixed;
  right: 30px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  span:nth-child(2) {
    top: 18px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  span:nth-child(3) {
    top: 36px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

`;

const SpanMenu = styled.span`
  width: 30px;
  height: 3px;
  background-color: black;
  transform-origin: 1px;
  transition: all 0.3s linear;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
`;

export default function Header() {
  const { isMobile } = useIsMobile();
  const { nickname, hasViewMatches, isFytTrainer } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(()=> {
    if (isMobile) {
      setOpen(true)
    }
  },[isMobile])

  return (
    <HeaderOuterContainer>
      <HeaderContainer className='container'>
          <FytIcon
            src={fytIcon}
            title="Fyt Icon"
            onClick={() => window.location = `${process.env.REACT_APP_API_URL}/start/home/`}
          />
          {isMobile &&
            <HamburgerButton className={sidebarOpen ? "open" : ""} onClick={() => setSidebarOpen(!sidebarOpen)}>
              <SpanMenu />
              <SpanMenu />
              <SpanMenu />
            </HamburgerButton>
          }
          {(isMobile && sidebarOpen) || (!isMobile) ? 
            <Links>
              {(!isFytTrainer && hasViewMatches) &&
                <LinkDiv>
                  <Link href={`${process.env.REACT_APP_API_URL}/match/view-matches/`}>
                    View Matches
                  </Link>
                </LinkDiv>
              }
              <LinkDiv>
              <Link href={`${process.env.REACT_APP_API_URL}/best-personal-trainers/reviews/`}>
                Testimonials
              </Link>
              </LinkDiv>
              <LinkDiv>
              <Link href={`${process.env.REACT_APP_API_URL}/faqs/`}>
                Help
              </Link>
              </LinkDiv>
              <LinkDiv>
                <Link href={`${process.env.REACT_APP_API_URL}/pricing/`}>
                  Pricing
                </Link>
              </LinkDiv>
              {nickname && !isMobile &&
                <LinkDiv>
                  <LinkButton onClick={() => setOpen(!open)}>
                    {nickname.split(' ')[0]}
                    <Span />
                  </LinkButton>
                </LinkDiv>
              }
                  {open &&
                    <Menu role="menu">
                      {isFytTrainer
                        ? <>
                          <MenuItem>
                            <LinkItem href={`${process.env.REACT_APP_API_URL}/scheduler/`}>
                              View Schedule
                            </LinkItem>
                          </MenuItem>
                          <MenuItem>
                            <LinkItem href={`${process.env.REACT_APP_API_URL}/fyt/account/`}>
                              My Account
                            </LinkItem>
                          </MenuItem>
                        </>
                        : <MenuItem>
                          <LinkItem href={`${process.env.REACT_APP_API_URL}/fyt/account/`}>
                            Dashboard
                          </LinkItem>
                        </MenuItem>
                      }
                      <MenuItem>
                        <LinkItem href={`${process.env.REACT_APP_API_URL}/chat/`}>
                          Messages
                        </LinkItem>
                      </MenuItem>
                      {isFytTrainer
                        ? <>
                          <MenuItem>
                            <LinkItem href={`${process.env.REACT_APP_API_URL}/fyt/account/clients`}>
                              View Clients
                            </LinkItem>
                          </MenuItem>
                          <MenuItem>
                            <LinkItem href={`${process.env.REACT_APP_API_URL}/trainer-faqs/`}>
                              FAQs
                            </LinkItem>
                          </MenuItem>
                          <MenuItem>
                            <LinkItem href={`${process.env.REACT_APP_API_URL}/fyt/account/edit-trainer/experience_and_education/`}>
                              Edit Profile
                            </LinkItem>
                          </MenuItem>
                          <MenuItem>
                            <LinkItem href={`${process.env.REACT_APP_API_URL}/fytproperks/`}>
                              FYT Pro Perks
                            </LinkItem>
                          </MenuItem>
                        </>
                        : <>
                          <MenuItem>
                            <LinkItem href={`${process.env.REACT_APP_API_URL}/fyt/account/edit/`}>
                              Account
                            </LinkItem>
                          </MenuItem>
                          <MenuItem>
                            <LinkItem href={`${process.env.REACT_APP_API_URL}/fyt/account/your_profile/`}>
                              Your Fyt Profile
                            </LinkItem>
                          </MenuItem>
                          <MenuItem>
                            <LinkItem href={`${process.env.REACT_APP_API_URL}/rewards/`}>
                              Rewards
                            </LinkItem>
                          </MenuItem>
                          <MenuItem>
                            <LinkItem href={`${process.env.REACT_APP_API_URL}/referrals/`}>
                              Free Sessions
                            </LinkItem>
                          </MenuItem>
                          <MenuItem>
                            <LinkItem href={`${process.env.REACT_APP_API_URL}/faqs/`}>
                              Help
                            </LinkItem>
                          </MenuItem>
                        </>
                      }
                      <MenuItem>
                        <LinkItem href={`${process.env.REACT_APP_API_URL}/logout/`}>
                          Sign Out
                        </LinkItem>
                      </MenuItem>
                    </Menu>
                  }
            </Links>
          : ''}
      </HeaderContainer>
    </HeaderOuterContainer>
  );
}