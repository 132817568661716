import React, { useEffect } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import backendService from "../services/backendService";
import { useAuthContext } from "../context/AuthProvider";
import ENV from "../env";
import { LoadingComponent } from "../navigation/ChatRouter";

const getEncodedId = (queryParams) => {
  if (queryParams.ei) {
    window.sessionStorage.setItem("ei", queryParams.ei);
    return queryParams.ei;
  } else if (!queryParams.ei) {
    const ei = sessionStorage.getItem("ei");
    if (ei) {
      return ei;
    }
    throw "Not Token available";
  }
};

export default function ChatRoute() {
  const location = useLocation();
  const { signIn } = useAuthContext();
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    try {
      const ei = getEncodedId(queryParams);
      backendService.getUserData(ei).then(user => {
        signIn({
          userId: user.pk,
          nickname: user.first_name + " " + user.last_name,
          accessToken: user.sendbird_auth_token,
          hasSubscriptions: user.has_subscriptions,
          hasPurchased: user.has_purchased,
          hasViewMatches: user.has_view_matches,
          trainersBooked: user.trainers_booked,
          usersBooked: user.users_booked,
          isFytTrainer: user.is_trainer
        });
      });
    } catch (e) {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV !== "development") {
        window.location = ENV.redirctUrl;
      }
    }
  }, [location]);
  return <LoadingComponent />;
}
