import React from "react";
import AppShell from "./components/AppShell";
import AuthProvider from "./context/AuthProvider";
import ChannelMessagesProvider from "./context/ChannelMessagesProvider";
import ChannelProvider from "./context/ChannelProvider";
import ChatProvider from "./context/ChatProvider";
import CurrentChannelProvider from "./context/CurrentChannelProvider";
import IsMobileProvider from "./context/IsMobileProvider";
import NewMessagesProvider from "./context/NewMessagesProvider";
import ChatRouter from "./navigation/ChatRouter";

function App() {
  return (
    <IsMobileProvider>
      <AuthProvider>
        <ChatProvider>
          <ChannelProvider>
            <NewMessagesProvider>
              <CurrentChannelProvider>
                <ChannelMessagesProvider>
                  <AppShell>
                    <ChatRouter />
                  </AppShell>
                </ChannelMessagesProvider>
              </CurrentChannelProvider>
            </NewMessagesProvider>
          </ChannelProvider>
        </ChatProvider>
      </AuthProvider>
    </IsMobileProvider>
  );
}

export default App;
