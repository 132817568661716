import React, { useContext, useEffect, useState } from "react";
import SendBird from "sendbird";

import { useAuthContext } from "./AuthProvider";
import env from "../env";
const ChatContext = React.createContext();

export const useChat = () => {
  const { chatInitializing, sendBirdSdk, senbirdError, sendbirdUser } =
    useContext(ChatContext);
  return { chatInitializing, sendBirdSdk, senbirdError, sendbirdUser };
};

export default function ChatProvider({ children }) {
  const { accessToken, userId, hasSubscriptions, hasPurchased, isFytTrainer } = useAuthContext();
  const [chatInitializing, setChatInitializing] = useState(true);
  const [sendBirdSdk, setSendbirdSdk] = useState();
  const [senbirdError, setSendbirdError] = useState();
  const [sendbirdUser, setSenbirdUser] = useState();
  useEffect(() => {}, [sendBirdSdk, chatInitializing]);
  useEffect(() => {
    if (accessToken && userId) {
      setSendbirdSdk(new SendBird({ appId: env.appId }));
    }
  }, [accessToken, userId]);
  useEffect(() => {
    if (sendBirdSdk && userId && accessToken) {
      sendBirdSdk.connect(String(userId), accessToken, (user, error) => {
        if (error) {
          setSendbirdError(error);
          console.log('sendBirdSdk.connect', error);
        }
        setChatInitializing(false);
        setSenbirdUser({
          ...user,
          hasSubscriptions: hasSubscriptions,
          hasPurchased: hasPurchased,
          isFytTrainer: isFytTrainer
        });
      });
    }
  }, [sendBirdSdk]);
  return (
    <ChatContext.Provider
      value={{ chatInitializing, sendBirdSdk, senbirdError, sendbirdUser }}
    >
      {children}
    </ChatContext.Provider>
  );
}
