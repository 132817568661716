import React, { useEffect, useState } from "react";
import { useChat } from "./ChatProvider";
const MessagesContext = React.createContext();
const CHANNEL_HANDLER_UNIQUE_ID = Math.round(Math.random() * 100);

const useMessagesHandler = (sendBirdSdk, chatInitializing) => {
  const [messages, setMessages] = useState([]);
  const addMessagesEventHandler = () => {
    const channelHandler = new sendBirdSdk.ChannelHandler();
    channelHandler.onMessageReceived = function (channel, message) {
      setMessages([...messages, message]);
    };
    sendBirdSdk.addChannelHandler(CHANNEL_HANDLER_UNIQUE_ID, channelHandler);
  };

  const removeMessage = (message) => {
    const filteredMessages = messages.filter(
      (prevMessage) => prevMessage.messageId !== message.messageId
    );
    setMessages(filteredMessages);
  };
  useEffect(() => {
    if (!chatInitializing && sendBirdSdk) {
      addMessagesEventHandler(sendBirdSdk);
    }
    return () => sendBirdSdk?.removeChannelHandler(CHANNEL_HANDLER_UNIQUE_ID);
  }, [chatInitializing, sendBirdSdk]);
  return { messages, removeMessage };
};

export const useNewMessages = () => {
  const { messages } = React.useContext(MessagesContext);
  return { messages };
};

export default function NewMessagesProvider({ children }) {
  const { chatInitializing, sendBirdSdk } = useChat();
  const { messages } = useMessagesHandler(sendBirdSdk, chatInitializing);
  return (
    <MessagesContext.Provider value={{ messages }}>
      {children}
    </MessagesContext.Provider>
  );
}
