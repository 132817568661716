import React, { useContext, useEffect, useState } from "react";
import { useChannels } from "./ChannelProvider";
const CurrentChannelContext = React.createContext();

export const useCurrentChannel = () => {
  const { channels, currentChannel, setCurrentChannelUrl, currentChannelUrl } =
    useContext(CurrentChannelContext);
  return { channels, currentChannel, setCurrentChannelUrl, currentChannelUrl };
};

export default function CurrentChannelProvider({ children }) {
  const { channels } = useChannels();
  const [currentChannelUrl, setCurrentChannelUrl] = useState(0);

  const [currentChannel, setCurrentChannel] = useState();

  useEffect(() => {
    if (channels && channels.length > 0 && !currentChannelUrl) {
      setCurrentChannelUrl(channels[0].url);
    }
  }, [channels, currentChannelUrl]);

  useEffect(() => {
    if (currentChannelUrl) {
      const selectedChannel = channels?.find(
        (channel) => channel.url === currentChannelUrl
      );
      setCurrentChannel(selectedChannel);
    }
  }, [currentChannelUrl]);
  return (
    <CurrentChannelContext.Provider
      value={{
        channels,
        currentChannel,
        setCurrentChannelUrl,
        currentChannelUrl,
      }}
    >
      {children}
    </CurrentChannelContext.Provider>
  );
}
