import { useEffect, useState } from "react";
import { useChat } from "../context/ChatProvider";

export const useGetCurrentChannel = (channelUrl) => {
  const [currentChannel, setCurrentChannel] = useState();
  const { sendBirdSdk } = useChat();
  useEffect(() => {
    if (channelUrl && sendBirdSdk) {
      sendBirdSdk.GroupChannel.getChannel(channelUrl, (channel, error) => {
        if (error) {
          return console.error(error);
        }
        setCurrentChannel(channel);
      });
    }
  }, [channelUrl, sendBirdSdk]);
  return currentChannel;
};
